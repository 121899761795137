import { Content } from '@prismicio/client';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';
import Text from 'components/@tedui/Text';
import { PrismicNextImage } from 'components/PRISMIC/PrismicNextImage';
import { crush } from '@tedconf/js-crushinator-helpers';
import PrismicConditionalLink from '../../PrismicConditionalLink';
import styles from 'components/PRISMIC/scss/pages.module.scss';

import type { JSX } from 'react';

/**
 * Props for `Image`.
 */
export type ImageProps = SliceComponentProps<Content.ImageSlice>;

const IMAGE_WIDTH = 1400;

/**
 * Component for "Image" Slices.
 */
const Image = ({ slice }: ImageProps): JSX.Element => {
  if (slice.variation !== 'legacyImage') {
    return (
      <div
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
      >
        <PrismicConditionalLink field={slice.primary.link}>
          <PrismicNextImage field={slice.primary.image} />
        </PrismicConditionalLink>
        <Text tag="div">
          <PrismicRichText field={slice.primary.caption} />
        </Text>
      </div>
    );
  }
  return (
    <div
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={styles['pages-image']}
    >
      <PrismicConditionalLink field={slice.primary.link}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          className={styles['pages-image__content']}
          src={crush(slice.primary.image_url || '', { width: IMAGE_WIDTH })}
          alt={slice.primary.image_alt || ''}
        />
      </PrismicConditionalLink>
      <div className={styles['pages-image__caption']}>
        <PrismicRichText field={slice.primary.caption} />
      </div>
    </div>
  );
};

export default Image;
